<template>
  <div>
    <nav-bar :nav-info='navInfo'></nav-bar>
    <div class="content">
      <div class="JDmap">
        <div id="container"></div>
        <div class="address">
          <el-input v-model="address" placeholder="当前选择地址"></el-input>
          <el-button type="primary">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  var { MapTypes } = JDMap.MapEnums
  export default {
    data () {
      return {
        address: ''
      }
    },
    mounted() {
      this.Map()
    },
    methods: {
      Map() {
        var map 
        map = new JDMap.Map("container", {
            zoom: 15,
        });
        var mark,
            from = MapTypes.QQMAP,
            to = MapTypes.QQMAP;
        var local = new JDMap.service.Location("test");
        local.getLocation().then((res) => {
          // console.log(res,'111111111')
          setCenter([res.result.lat, res.result.lng]);
        });
        function setCenter(geoLocation) {
          map.setView(new JDMap.LatLng(geoLocation));
          if (mark) {
            map.removeLayer(mark);
          }
          mark = new JDMap.Marker(geoLocation).addTo(map);
        }
        var latlng, geocoder
        //地图点击时，获取点击地经纬度
        map.on('click', (e) => {
          console.log(e)
          latlng = e.latlng
          regeoCode()
        })
        let regeoCode = () => {
          if (!geocoder) {
            geocoder = new JDMap.service.Geocode("test")
          }
          if (!mark) {
            mark = new AMap.Marker()
            mark.addTo(map)
          }
          mark.setLatLng(latlng)//设置标记的位置
          console.log(latlng,'6666')
          var geoLocation = { 
            lat: latlng.lat, 
            lng: latlng.lng,
          }
          geocoder.regeo(geoLocation)
          .then(res => {
            console.log(res,99999)
            this.address = res.result.address
          })
          // mark.setMap(map);//在地图上显示一个标记
        }  

      },    
    }
  }
</script>
<style scoped lang="scss">
.content {
  margin: 10px;
  .JDmap {
    position: relative;
    width: 100%;
    height: 450px;
    border-radius: 15px;
    box-sizing: border-box;
    border: 1px solid #ececec;
    .address {
      position: absolute;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      bottom: 0;
      width: 100%;
      z-index: 999;
      padding: 10px;
      border-radius: 15px;
      background: #FFF;
      /deep/ .el-input {
        margin-bottom: 10px;
      }
      /deep/ .el-input__inner {
        border: none;
        background: #f7f8fc;
      }
    }
    #container {
      width: 100%;
      height: 90%;
      border-radius: 15px 15px 0 0;
    }
  }  
}



</style>